export default {
  name: 'SelectChecbox',
  components: {},
  props: {
    value: {
      type: Array,
      required: false,
    },
    error: {
      type: Number,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    isMultiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    isAllowAll: {
      type: Boolean,
      required: false,
      default: true,
    },
    columns: {
      type: Number,
      required: false,
      default: 1,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    isAutoApply: {
      type: Boolean,
      required: false,
      default: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    default: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      open: false,
      selectAll: false,
      selectedItem: this.value,
      oldSelectedItem: this.value,
    };
  },
  created() {
    // Check khi option và value đã được khai báo sẵn
    if (this.value.length == this.options.length) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  },
  watch: {
    value() {
      this.selectedItem = this.value;
      this.oldSelectedItem = this.value;
      // Check khi option và value được gán sau khi gọi api
      if (this.value.length == this.options.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    options() {
      // Check khi option và value được gán sau khi gọi api
      if (this.value.length == this.options.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
  },
  methods: {
    clickOutSide() {
      // Nếu click ra ngoài checkbox khi isAutoApply = false, reset lại giá trị gần nhất
      this.open = false;
      if (!this.isAutoApply) {
        this.clickedCancel();
      }
    },
    onChangeSelectAll() {
      if (this.selectAll) {
        this.selectedItem = this.options.map((x) => x.value);
      } else {
        this.selectedItem = [];
      }
      if (this.isAutoApply) {
        this.clickedApply();
      }
    },
    updateValue() {
      if (this.selectedItem.length == this.options.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
      if (this.isAutoApply) {
        this.clickedApply();
      }
    },
    isDisabled(id) {
      if (!this.isMultiple) {
        if (this.selectedItemm.length > 0 && !this.selectedItemm.includes(id)) {
          return true;
        }
        return false;
      }
    },
    clickedApply(forceClose = false) {
      this.oldSelectedItem = this.selectedItem;
      this.$emit('input', this.selectedItem);
      if (forceClose) {
        this.open = false;
      }
    },
    clickedCancel() {
      this.selectedItem = this.oldSelectedItem;
      if (this.selectedItem.length == this.options.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
      this.open = false;
    },
    clickedDefault() {
      this.selectedItem = this.default;
      if (this.selectedItem.length == this.options.length) {
        this.selectAll = true;
      } else {
        this.selectAll = false;
      }
    },
    onClickPicker() {
      this.open = !this.open;
    },
  },
};
