<template>
  <div class="calendar-date">
    <div class="header-date p-1">
      <div
        v-if="showDropdowns"
        :colspan="showWeekNumbers ? 6 : 5"
        class="month"
      >
        <div class="row mx-1">
          <select v-model="month" class="monthselect col">
            <option
              v-for="(m, idx) in months"
              :key="idx"
              :value="m.value + 1"
              :disabled="!m.enabled"
            >
              {{ m.label }}
            </option>
          </select>
          <input
            ref="yearSelect"
            type="number"
            v-model="year"
            @blur="checkYear"
            class="yearselect col"
          />
        </div>
      </div>
      <div v-else :colspan="showWeekNumbers ? 6 : 5" class="month">
        {{ monthName }} {{ year }}
      </div>
      <div>
        <ez-icon
          icon="ez_chevron_left"
          size="24"
          class="cursor-pointer prev available mr-1"
          @click="prevMonthClick"
        />
        <ez-icon
          icon="ez_chevron_right"
          size="24"
          class="cursor-pointer next available"
          @click="nextMonthClick"
        />
      </div>
    </div>
    <div class="body-date p-1">
      <table>
        <tbody>
          <tr>
            <th v-if="showWeekNumbers" class="week">{{ locale.weekLabel }}</th>
            <th v-for="(weekDay, idx) in locale.daysOfWeek" :key="idx">
              {{ weekDay }}
            </th>
          </tr>
          <tr v-for="(dateRow, index) in calendar" :key="index">
            <td
              v-if="showWeekNumbers && (index % 7 || index === 0)"
              class="week"
            >
              {{ $dateUtil.weekNumber(dateRow[0]) }}
            </td>
            <td
              v-for="(date, idx) in dateRow"
              :class="dayClass(date)"
              @click="canClick(date) ? $emit('date-click', date) : ''"
              @mouseover="$emit('hover-date', date)"
              :key="idx"
              :data-date="date.toISOString().substring(0, 10)"
            >
              <div>
                {{ date.getDate() }}
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import dateUtilMixin from './dateUtilMixin';

export default {
  mixins: [dateUtilMixin],
  name: 'calendar',
  props: {
    monthDate: Date,
    localeData: Object,
    currentDate: Date,
    minDate: Date,
    maxDate: Date,
    showDropdowns: {
      type: Boolean,
      default: false,
    },
    showWeekNumbers: {
      type: Boolean,
      default: false,
    },
    dateFormat: {
      type: Function,
      default: null,
    },
  },
  data() {
    let currentMonthDate = this.monthDate || this.currentDate || new Date();
    return {
      currentMonthDate,
      year_text: currentMonthDate.getFullYear(),
    };
  },
  methods: {
    prevMonthClick() {
      this.changeMonthDate(this.$dateUtil.prevMonth(this.currentMonthDate));
    },
    nextMonthClick() {
      this.changeMonthDate(this.$dateUtil.nextMonth(this.currentMonthDate));
    },
    changeMonthDate(date, emit = true) {
      let year_month = this.$dateUtil.yearMonth(this.currentMonthDate);
      this.currentMonthDate = this.$dateUtil.validateDateRange(
        date,
        this.minDate,
        this.maxDate
      );
      if (
        emit &&
        year_month !== this.$dateUtil.yearMonth(this.currentMonthDate)
      ) {
        this.$emit('change-month', {
          month: this.currentMonthDate.getMonth() + 1,
          year: this.currentMonthDate.getFullYear(),
        });
      }
      this.checkYear();
    },
    dayClass(date) {
      let dt = new Date(date);
      dt.setHours(0, 0, 0, 0);

      let dt_min_compare = new Date(dt);
      dt_min_compare.setHours(23, 59, 59, 999);

      let classes = {
        off: date.getMonth() + 1 !== this.month,
        weekend: date.getDay() === 6 || date.getDay() === 0,
        today: dt.setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0),
        active:
          dt.setHours(0, 0, 0, 0) ==
          new Date(this.currentDate).setHours(0, 0, 0, 0),

        disabled:
          (this.minDate && dt_min_compare.getTime() < this.minDate.getTime()) ||
          (this.maxDate && dt.getTime() > this.maxDate.getTime()),
      };

      return this.dateFormat ? this.dateFormat(classes, date) : classes;
    },
    canClick(date) {
      let dt = new Date(date);
      dt.setHours(0, 0, 0, 0);

      let dt_min_compare = new Date(dt);
      dt_min_compare.setHours(23, 59, 59, 999);

      if (
        (this.minDate && dt_min_compare.getTime() < this.minDate.getTime()) ||
        (this.maxDate && dt.getTime() > this.maxDate.getTime())
      ) {
        return false;
      } else {
        return true;
      }
    },
    checkYear() {
      if (this.$refs.yearSelect !== document.activeElement) {
        this.$nextTick(() => {
          this.year_text = this.monthDate.getFullYear();
        });
      }
    },
  },
  computed: {
    monthName() {
      return this.locale.monthNames[this.currentMonthDate.getMonth()];
    },
    year: {
      get() {
        return this.year_text;
      },
      set(value) {
        this.year_text = value;
        let newDate = this.$dateUtil.validateDateRange(
          new Date(value, this.month, 1),
          this.minDate,
          this.maxDate
        );
        if (this.$dateUtil.isValidDate(newDate)) {
          this.$emit('change-month', {
            month: newDate.getMonth(),
            year: newDate.getFullYear(),
          });
        }
      },
    },
    month: {
      get() {
        return this.currentMonthDate.getMonth() + 1;
      },
      set(value) {
        let newDate = this.$dateUtil.validateDateRange(
          new Date(this.year, value - 1, 1),
          this.minDate,
          this.maxDate
        );

        this.$emit('change-month', {
          month: newDate.getMonth() + 1,
          year: newDate.getFullYear(),
        });
      },
    },
    calendar() {
      let month = this.month;
      let year = this.currentMonthDate.getFullYear();
      let firstDay = new Date(year, month - 1, 1);
      let lastMonth = this.$dateUtil.prevMonth(firstDay).getMonth() + 1;
      let lastYear = this.$dateUtil.prevMonth(firstDay).getFullYear();
      let daysInLastMonth = new Date(lastYear, month - 1, 0).getDate();

      let dayOfWeek = firstDay.getDay();

      let calendar = [];

      for (let i = 0; i < 6; i++) {
        calendar[i] = [];
      }

      let startDay = daysInLastMonth - dayOfWeek + this.locale.firstDay + 1;
      if (startDay > daysInLastMonth) startDay -= 7;

      if (dayOfWeek === this.locale.firstDay) startDay = daysInLastMonth - 6;

      let curDate = new Date(lastYear, lastMonth - 1, startDay, 12, 0, 0);
      for (
        let i = 0, col = 0, row = 0;
        i < 6 * 7;
        i++, col++, curDate.setDate(curDate.getDate() + 1)
      ) {
        if (i > 0 && col % 7 === 0) {
          col = 0;
          row++;
        }
        calendar[row][col] = new Date(curDate.getTime());
      }

      return calendar;
    },
    months() {
      return this.locale.monthNames.map((m, idx) => ({
        label: m,
        value: idx,
        enabled:
          (!this.maxDate || this.maxDate >= new Date(this.year, idx, 1)) &&
          (!this.minDate || this.minDate <= new Date(this.year, idx + 1, 0)),
      }));
    },
    locale() {
      return this.$dateUtil.localeData(this.localeData);
    },
  },
  watch: {
    monthDate(value) {
      if (this.currentMonthDate.getTime() !== value.getTime()) {
        this.changeMonthDate(value, false);
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/calendar.scss';
</style>
