<template>
  <i
    :class="'ez-icon ' + icon"
    :style="'font-size:' + size + 'px; color:' + color"
    @click="$emit('click', $event)"
  />
</template>

<style lang="scss" scoped>
.ez-icon {
  vertical-align: middle;
  &:before {
    color: currentColor;
  }
}
</style>
<script>
export default {
  name: 'EzIcon',

  props: {
    icon: {
      type: String,
      default: 'ez_ads_click',
    },
    size: {
      type: String | Number,
      default: 'inherit',
    },
    color: {
      type: String,
    },
  },
};
</script>
