import { $themeBreakpoints } from '@themeConfig'
import { getAppResourceApi } from '@/api/action-defines/resource'
const resourceModels = {
  MenusRoles: [],
  Sites: [],
  GroupUsers: [],
};
export default {
  namespaced: true,
  state: {
    windowWidth: 0,
    shallShowOverlay: false,
    resources: {...resourceModels},
  },
  getters: {
    currentBreakPoint: state => {
      const { windowWidth } = state
      if (windowWidth >= $themeBreakpoints.xl) return 'xl'
      if (windowWidth >= $themeBreakpoints.lg) return 'lg'
      if (windowWidth >= $themeBreakpoints.md) return 'md'
      if (windowWidth >= $themeBreakpoints.sm) return 'sm'
      return 'xs'
    },
    getMenus: state =>  {
      return (state.resources.MenusRoles || []).filter(s => s.MenuType !== 'ACTION')
    },
    getSites: state =>  {
      return (state.resources.Sites || [])
    },
    allowOperation: state => actionCode => (state.resources.MenusRoles || []).some(s => s.MenuType === 'ACTION' && s.MenuCode === actionCode),
    allowAccessPage: state => pageCode => (state.resources.MenusRoles || []).some(s => s.MenuType === 'PAGE' && s.MenuCode === pageCode),
    userSiteGroupRoleInfo: state => siteID => (state.resources.Sites || []).find(s => s.SiteID === siteID) || null,
  },
  mutations: {
    UPDATE_WINDOW_WIDTH(state, val) {
      state.windowWidth = val
    },
    TOGGLE_OVERLAY(state, val) {
      state.shallShowOverlay = val !== undefined ? val : !state.shallShowOverlay
    },
    UPDATE_RESOURCES(state, resourcesUpdate) {
      if (resourcesUpdate) {
        Object.keys(state.resources).forEach(key => {
          if (key in resourcesUpdate) {
            state.resources[key] = resourcesUpdate[key]
          }
        });
      }
    },
    CLEAR_RESOURCE(state) {
      state.resources = {...resourceModels};
    },
  },
  actions: {
    getAppResources(context) {
      getAppResourceApi().then(res => {
        if (res.IsSuccess) {
          context.commit('UPDATE_RESOURCES', res.Data)
        }
      })
    },
    clearResource(context) {
      context.commit('CLEAR_RESOURCE')
    }
  },
}
