<template>
  <div class="ez-datetime-picker">
    <div
      @click="onClickPicker"
      ref="toggle"
      class="cursor-pointer ez-input-mask"
      id="ez-input-date"
    >
      <template v-if="isInputMask">
        <cleave
          :name="Math.random()"
          v-model="inputDateMaskData"
          class="form-control"
          :raw="false"
          :options="dateMask"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="state ? 'is-invalid' : ''"
        />
      </template>
      <template v-else>
        <input
          readonly
          type="text"
          :value="inputDateMaskData"
          :placeholder="placeholder"
          class="form-control"
          :disabled="disabled"
          :class="state ? 'is-invalid' : ''"
        />
      </template>
      <div>
        <ez-icon
          icon="ez_clear"
          size="16"
          v-if="isCanClear && inputDateMaskData"
          @click="clear()"
          class="clear-date"
        />
        <ez-icon icon="ez_calendar_today" size="16" />
      </div>
    </div>

    <div
      id="ez-popup-date"
      class="ez-datetime-picker-wrapper"
      v-if="open || opens === 'inline'"
      v-append-to-body
      ref="dropdown"
    >
      <div class="calendar-container" v-if="showCalendars">
        <calendar
          :monthDate="monthDate"
          :locale-data="locale"
          :currentDate="newDatePicker"
          :minDate="min"
          :maxDate="max"
          :show-dropdowns="showDropdowns"
          @change-month="changeLeftMonth"
          :date-format="dateFormatFn"
          @date-click="dateClick"
          :showWeekNumbers="showWeekNumbers"
        />

        <calendar-time
          v-if="timePicker && newDatePicker"
          @update="onUpdateStartTime"
          :miniute-increment="timePickerIncrement"
          :hour24="timePicker24Hour"
          :second-picker="timePickerSeconds"
          :current-time="newDatePicker"
          :readonly="readonly"
        />
      </div>

      <div class="px-1 pb-1 pt-0 d-flex justify-content-end" v-if="!autoApply">
        <b-button
          variant="flat"
          @click="clickCancel"
          v-if="!readonly"
          class="mr-1"
        >
          {{ $t('common_cancel') }}
        </b-button>

        <b-button
          variant="primary"
          type="submit"
          @click="clickedApply"
          v-if="!readonly"
        >
          {{ $t('common_confirm') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { createPopper } from '@popperjs/core';

import Cleave from 'vue-cleave-component';
import dateUtilMixin from './dateUtilMixin';
import Calendar from './Calendar.vue';
import CalendarTime from './CalendarTime';
import { getDateUtil } from './util';
import appendToBody from '../directives/appendToBody';
import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export default {
  components: { Calendar, CalendarTime, Cleave },
  mixins: [dateUtilMixin],
  directives: { appendToBody },
  model: {
    prop: 'datePicker',
    event: 'update',
  },
  props: {
    minDate: {
      type: [String, Date],
      default() {
        return null;
      },
    },
    maxDate: {
      type: [String, Date],
      default() {
        return null;
      },
    },
    showWeekNumbers: {
      type: Boolean,
      default: false,
    },
    showDropdowns: {
      type: Boolean,
      default: false,
    },
    timePicker: {
      type: Boolean,
      default: false,
    },
    timePickerIncrement: {
      type: Number,
      default: 5,
    },
    timePicker24Hour: {
      type: Boolean,
      default: true,
    },
    timePickerSeconds: {
      type: Boolean,
      default: false,
    },
    autoApply: {
      type: Boolean,
      default: false,
    },
    localeData: {
      type: Object,
      default() {
        return {};
      },
    },
    datePicker: {
      // for v-model
      type: Date,
      default: null,
      required: false,
    },
    opens: {
      type: String,
      default: 'center',
    },
    dateFormat: Function,
    alwaysShowCalendars: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    calculatePosition: {
      type: Function,
      /**
       * @param dropdownList {HTMLUListElement}
       * @param component {Vue} current instance of vue date range picker
       * @param width {int} calculated width in pixels of the dropdown menu
       * @param top {int} absolute position top value in pixels relative to the document
       * @param left {int} absolute position left value in pixels relative to the document
       * @param right {int} absolute position right value in pixels relative to the document
       * @return {function|void}
       */
      default(dropdownList, component, { width, top, left, right }) {
        // which way the picker opens - "center", "left" or "right"
        if (component.opens === 'center') {
          // console.log('center open', left, width)
          dropdownList.style.left = left + width / 2 + 'px';
        } else if (component.opens === 'left') {
          // console.log('left open', right, width)
          dropdownList.style.right = window.innerWidth - right + 'px';
        } else if (component.opens === 'right') {
          // console.log('right open')
          dropdownList.style.left = left + 'px';
        }
        dropdownList.style.top = top + 'px';
        // dropdownList.style.width = width + 'px'
      },
    },
    closeOnEsc: {
      type: Boolean,
      default: true,
    },
    readonly: {
      type: Boolean,
    },
    state: {
      type: Boolean,
    },
    isInputMask: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
    isCanClear: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    //copy locale data object
    const util = getDateUtil();
    let data = { locale: util.localeData({ ...this.localeData }) };

    data.dateMask = {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],

      // Cho lấy theo format
      // delimiters: ['/', '/', ' ', ':'],
      // blocks: [2, 2, 4, 2, 2],
    };
    if (this.datePicker) {
      data.inputDateMaskData = dayjs(this.datePicker).format('DD/MM/YYYY');
    } else {
      data.inputDateMaskData = '';
    }

    data.newDatePicker = this.datePicker || null;
    data.monthDate = this.datePicker ? new Date(this.datePicker) : new Date();

    //get next month date
    data.nextMonthDate = util.nextMonth(data.monthDate);

    data.open = false;

    // update day names order to firstDay
    if (data.locale.firstDay !== 0) {
      let iterator = data.locale.firstDay;
      let weekDays = [...data.locale.daysOfWeek];
      while (iterator > 0) {
        weekDays.push(weekDays.shift());
        iterator--;
      }
      data.locale.daysOfWeek = weekDays;
    }
    return data;
  },
  methods: {
    clear() {
      this.inputDateMaskData = '';
      this.newDatePicker = null;

      this.$emit('update', null);
      this.$emit('confirm', null);
    },
    //calculate initial month selected in picker
    selectMonthDate() {
      let dt = this.newDatePicker || new Date();

      this.changeLeftMonth({
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
      });

      this.changeRightMonth({
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
      });
      // console.log('selectMonthDate', this.monthDate)
    },
    dateFormatFn(classes, date) {
      let dt = new Date(date);
      dt.setHours(0, 0, 0, 0);
      let start = new Date(this.start);
      start.setHours(0, 0, 0, 0);
      let end = new Date(this.end);
      end.setHours(0, 0, 0, 0);

      classes['in-range'] = dt >= start && dt <= end;

      return this.dateFormat ? this.dateFormat(classes, date) : classes;
    },
    changeLeftMonth(value) {
      let newDate = new Date(value.year, value.month - 1, 1);
      this.monthDate = newDate;

      this.$emit('change-month', this.monthDate, 0);
    },
    changeRightMonth(value) {
      let newDate = new Date(value.year, value.month - 1, 1);
      this.nextMonthDate = newDate;
      this.$emit('change-month', this.nextMonthDate, 1);
    },
    normalizeDatetime(value, oldValue) {
      let newDate = new Date(value);
      if (this.timePicker && oldValue) {
        newDate.setHours(oldValue.getHours());
        newDate.setMinutes(oldValue.getMinutes());
        newDate.setSeconds(oldValue.getSeconds());
        newDate.setMilliseconds(oldValue.getMilliseconds());
      }

      return newDate;
    },
    dateClick(value) {
      if (this.readonly) return false;
      this.newDatePicker = this.normalizeDatetime(value, this.newDatePicker);
      if (this.autoApply) this.clickedApply();
    },
    onClickPicker() {
      if (!this.disabled) {
        this.togglePicker(null, true);
      }
    },
    togglePicker(value, event) {
      if (typeof value === 'boolean') {
        this.open = value;
      } else {
        this.open = !this.open;
      }

      if (event === true)
        /**
         * Emits whenever the picker opens/closes
         * @param {boolean} open - the current state of the picker
         * @param {Function} togglePicker - function (show, event) which can be used to control the picker. where "show" is the new state and "event" is boolean indicating whether a new event should be raised
         */
        this.$emit('toggle', this.open, this.togglePicker);
    },
    clickedApply() {
      this.open = false;
      this.togglePicker(false, true);

      this.$emit('update', this.newDatePicker);
      this.$emit('confirm', this.newDatePicker);
      this.inputDateMaskData = dayjs(this.newDatePicker).format('DD/MM/YYYY');
    },
    clickCancel() {
      if (this.open) {
        // reset start and end

        let startDate = this.datePicker;
        let endDate = this.datePicker;

        // this.start = startDate ? new Date(startDate) : null;
        // this.end = endDate ? new Date(endDate) : null;
        this.togglePicker(false, true);
      }
    },
    clickAway($event) {
      if (
        $event &&
        $event.target &&
        !this.$el.contains($event.target) &&
        this.$refs.dropdown &&
        !this.$refs.dropdown.contains($event.target)
      ) {
        this.clickCancel();
      }
    },
    onUpdateStartTime(value) {
      let newTime = new Date(this.newDatePicker);
      newTime.setHours(value.hours);
      newTime.setMinutes(value.minutes);
      newTime.setSeconds(value.seconds);

      this.newDatePicker = this.$dateUtil.validatedatePicker(
        newTime,
        this.minDate,
        this.maxDate
      );

      // if autoapply is ON we should update the value on time selection change
      if (this.autoApply) {
        this.$emit('update', this.newDatePicker);
        this.inputDateMaskData = dayjs(this.newDatePicker).format('DD/MM/YYYY');
      }
    },
    onUpdateEndTime(value) {
      let newTime = new Date(this.newDatePicker);
      newTime.setHours(value.hours);
      newTime.setMinutes(value.minutes);
      newTime.setSeconds(value.seconds);

      this.newDatePicker = this.$dateUtil.validatedatePicker(
        newTime,
        this.minDate,
        this.maxDate
      );

      // if autoapply is ON we should update the value on time selection change
      if (this.autoApply) {
        this.$emit('update', this.newDatePicker);
        this.inputDateMaskData = dayjs(this.newDatePicker).format('DD/MM/YYYY');
      }
    },
    handleEscape(e) {
      if (this.open && e.keyCode === 27 && this.closeOnEsc) {
        this.clickCancel();
      }
    },
    _dayjs: dayjs,
  },
  computed: {
    showCalendars() {
      return this.alwaysShowCalendars || this.showCustomRangeCalendars;
    },
    min() {
      return this.minDate ? new Date(this.minDate) : null;
    },
    max() {
      return this.maxDate ? new Date(this.maxDate) : null;
    },
  },
  watch: {
    minDate() {
      this.selectMonthDate();
      let dt = this.$dateUtil.validatedatePicker(
        this.monthDate,
        this.minDate || new Date(),
        this.maxDate
      );
      this.changeLeftMonth({
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
      });
    },
    maxDate() {
      this.selectMonthDate();
      let dt = this.$dateUtil.validatedatePicker(
        this.nextMonthDate,
        this.minDate,
        this.maxDate || new Date()
      );
      this.changeRightMonth({
        year: dt.getFullYear(),
        month: dt.getMonth() + 1,
      });
    },
    inputDateMaskData() {
      if (dayjs(this.inputDateMaskData, 'DD/MM/YYYY').isValid()) {
        this.newDatePicker = dayjs(
          this.inputDateMaskData,
          'DD/MM/YYYY'
        ).toDate();

        this.$emit('update', this.newDatePicker);
        this.selectMonthDate(); //select initial visible months
      }
    },
    open: {
      handler(value) {
        if (typeof document === 'object') {
          this.selectMonthDate(); //select initial visible months

          this.$nextTick(() => {
            value
              ? document.body.addEventListener('click', this.clickAway)
              : document.body.removeEventListener('click', this.clickAway);
            value
              ? document.addEventListener('keydown', this.handleEscape)
              : document.removeEventListener('keydown', this.handleEscape);
            if (value) {
              const inputDate = document.querySelector('#ez-input-date');
              const popupDate = document.querySelector('#ez-popup-date');
              createPopper(inputDate, popupDate, {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, 10],
                    },
                  },
                ],
              });
            }
          });
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../assets/daterangepicker.scss';
</style>

<style lang="scss" scoped></style>
