import apps from './routes/apps';
import dashboard from './routes/dashboard';
import uiElements from './routes/ui-elements/index';
import pages from './routes/pages';
import chartsMaps from './routes/charts-maps';
import formsTable from './routes/forms-tables';
import others from './routes/others';
import siteManagement from './routes/site-management';
import userManagement from './routes/user-management';
import serialManagement from './routes/serial-management';
import searchInvoiceManagement from './routes/search-invoice-management';
export default [
  { path: '/', redirect: { name: 'home' } },
  ...apps,
  ...dashboard,
  ...pages,
  ...chartsMaps,
  ...formsTable,
  ...uiElements,
  ...others,
  ...siteManagement,
  ...userManagement,
  ...serialManagement,
  ...searchInvoiceManagement,
  {
    path: '*',
    redirect: 'error-404',
  },
];
