export default [
  {
    path: '/configuration/serial-management',
    name: 'serial-management',
    component: () =>
      import('@/views/modules/serial-management/SerialManagement.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
