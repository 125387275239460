import { i18n } from '@/libs/i18n';
import Vue from 'vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export const errorFormatter = (error) => {
  // If server error
  Vue.$toast(
    {
      component: ToastificationContent,
      props: {
        title: error.response?.status,
        icon: 'ez_error',
        text: i18n.tc(error.response?.statusText),
        variant: 'danger',
      },
    },
    {
      position: 'top-right',
    }
  );

  return error;
};

export const responseFormatter = (response) => {
  // If API error
  if (response.data.StatusCode != 200) {
    Vue.$toast(
      {
        component: ToastificationContent,
        props: {
          title: response.data.StatusCode,
          icon: 'ez_error',
          text: i18n.tc(response.data.Messages),
          variant: 'danger',
        },
      },
      {
        position: 'top-right',
      }
    );
  }

  if (response.data && response.data.data) {
    return { ...response, ...response.data };
  }
  return response;
};
