import { $GET } from '@/api/gateway/gatewayMethods';
import {
  GET_TRANSLATIONS,
  GET_LIST_RESOURCE,
} from '@/api/endpoint-defines/common';

export const getTranslationApi = ({ QueryParameters }) =>
  $GET({ EndPoint: GET_TRANSLATIONS, QueryParameters });

export const getListResourceApi = ({ QueryParameters }) =>
  $GET({ EndPoint: GET_LIST_RESOURCE, QueryParameters });
