import Vue from 'vue';
import {
  ToastPlugin,
  ModalPlugin,
  BootstrapVue,
  VBTogglePlugin,
} from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import ezIcon from '@/@core/components/ez-icon/ezIcon.vue';
import vSelect from 'vue-select';
import SelectCheckbox from '@/@core/components/ez-select-checkbox/SelectCheckbox.vue';
import FilterCheckbox from '@/@core/components/ez-filter-checkbox/FilterCheckbox.vue';
import ConfirmModal from '@/@core/components/ez-confirm-modal/ConfirmModal.vue';
import DatePicker from '@core/components/ez-date-picker';

import { i18n } from '@/libs/i18n';
import router from './router';
import store from './store';
import App from './App.vue';

// Global Components
import './global-components';

// 3rd party plugins
import '@axios';
import '@/libs/acl';
import '@/libs/portal-vue';
import '@/libs/clipboard';
import '@/libs/toastification';
import '@/libs/sweet-alerts';
import '@/libs/vue-select';
import '@/libs/tour';

// Axios Mock Adapter
import '@/@fake-db/db';

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(BootstrapVue);
Vue.use(VBTogglePlugin);

// Composition API
Vue.use(VueCompositionAPI);

Vue.component('ez-filter-checkbox', FilterCheckbox);
Vue.component('ez-select-checkbox', SelectCheckbox);
Vue.component('ez-confirm-modal', ConfirmModal);
Vue.component('ez-icon', ezIcon);
Vue.component('v-select', vSelect);
Vue.component('ez-date-picker', DatePicker);

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css'); // For form-wizard

require('@core/assets/fonts/ez-icon/iconfont.css');

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

import 'devextreme/dist/css/dx.light.css';
// import core styles
require('@core/scss/base/plugins/data-grid/data-grid.scss');

Vue.directive('click-outside', {
  bind(el, binding, vnode) {
    var vm = vnode.context;
    var callback = binding.value;

    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        return callback.call(vm, event);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unbind(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  },
});

// var utc = require('dayjs/plugin/utc');
// var timezone = require('dayjs/plugin/timezone'); // dependent on utc plugin
// const dayjs = require('dayjs');
// dayjs.extend(utc);
// dayjs.extend(timezone);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
