export default [
  {
    path: '/configuration/user-management',
    name: 'user-management',
    redirect: '/user-management/role',
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import('@/views/modules/user-management/UserManagement.vue'),
    children: [
      {
        path: 'account',
        name: 'user-management-account',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/modules/user-management/user-list/UserList.vue'),
      },
      {
        path: 'role',
        name: 'user-management-role',
        meta: {
          requiresAuth: true,
        },
        component: () =>
          import('@/views/modules/user-management/role-list/RoleList.vue'),
      },
    ],
  },
];
