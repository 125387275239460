import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  // props: ['title', 'label', 'placeholder', 'labelValidationName', 'variant'], // variant: confirm | delete
  props: {
    title: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    labelValidationName: {
      type: String,
      required: false,
    },
    variant: {
      type: String,
      required: false,
      default: 'confirm',
    },
    noReason: {
      type: Boolean,
      required: false,
      default: false,
    },
    confirmLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isOpen: false,
      confirmInput: '',
    };
  },
  emits: ['handle-confirm'],
  watch: {
    isOpen(value) {
      if (!value) {
        this.confirmInput = '';
      }
    },
  },
  methods: {
    handleConfirm() {
      if (!this.noReason) {
        this.$refs.confirmModal.validate().then((success) => {
          if (success) {
            this.$emit('handle-confirm', this.confirmInput);

            this.$refs.confirmModal.reset();
          }
        });
      } else {
        this.$emit('handle-confirm');
      }
    },
  },
};
