/**
 * OpenID Connect flow using oidc-client-js library(@see: https://github.com/IdentityModel/oidc-client-js)
 * @author QuocPhong88L1 <phongpq2402@gmail.com>
 */
import Vue from 'vue';
import { i18n } from '@/libs/i18n';
import { getOidcClient } from '@/auth/oidc/auth.service';
import router from '@/router';
import {
  authAccount,
  signout,
  switchSiteAPI,
} from '@/api/action-defines/account';

const defaultState = {
  user: {},
  oidcAction: {},
};

let oidcClient = getOidcClient();

const actions = {
  signin: () => {
    oidcClient.signinRedirect();
  },
  signinRedirectCallback: (context) => {
    oidcClient
      .signinRedirectCallback()
      .then((response) => {
        context.dispatch('setAuthenticationSuccess', response);
      })
      .catch((error) => {
        context.dispatch('showConfirmSiginError');
      });
  },
  setAuthenticationSuccess: (context, user) => {
    const userAuthenBodyData = {
      ezIDToken: user.access_token,
    };
    context.dispatch('appAuthen', userAuthenBodyData);
  },
  signOut: (context) => {
    context.commit('LOGOUT_REQUEST_PENDING');
    oidcClient.getUser().then((user) => {
      if (user) {
        oidcClient.signoutRedirect().catch(() => {
          context.commit('LOGOUT_REQUEST_FAILURE');
        });
      } else {
        if (context.getters['userIsAuthenticated']) {
          context.dispatch('signoutAPI');
        } else {
          context.commit('LOGOUT_REQUEST_SUCCESS');
          router.push({ name: 'home' });
        }
      }
    });
  },
  signoutRedirectCallback: (context) => {
    oidcClient
      .signoutRedirectCallback()
      .then((user) => {
        oidcClient.removeUser();
        if (context.getters['userIsAuthenticated']) {
          context.dispatch('signoutAPI');
        } else {
          context.commit('LOGOUT_REQUEST_SUCCESS');
          router.push({ name: 'home' });
        }
      })
      .catch(() => {
        context.commit('LOGOUT_REQUEST_SUCCESS');
        router.push({ name: 'home' });
      });
  },
  checkAccess: (context, requiresAuth) => {
    return new Promise((resolve) => {
      if (!requiresAuth) return resolve('OK');
      // Check if we have a token in Session Storage
      const getUserPromise = new Promise((resolve) => {
        oidcClient
          .getUser()
          .then((user) => {
            resolve(user);
          })
          .catch(() => {
            resolve(null);
          });
      });
      let statusCode = 'UNAUTHORIZED';
      getUserPromise.then((user) => {
        // Check if we have token information and if token is not expired
        if (user && user.access_token) {
          statusCode = 'OK';
          context.dispatch('setAuthenticationSuccess', user);
        }
        resolve(statusCode);
      });
    });
  },
  appAuthen(context, userAuthenBodyData) {
    authAccount({ BodyData: userAuthenBodyData })
      .then((res) => {
        if (res.IsSuccess) {
          const userResponseData = res.Data;
          const user = {
            ezIDAccessToken: userAuthenBodyData.ezIDToken,
            AccessToken: userResponseData.Token,
            Expiration: userResponseData.Expiration,
            SiteID: userResponseData.SiteID,
            LanguageCode: userResponseData.LanguageCode,
            UserID: userResponseData.UserID,
            Profile: {
              Email: userResponseData.Email,
              Avatar: userResponseData.Avatar,
              FullName: userResponseData.FullName,
              UserName: userResponseData.UserName,
            },
          };
          context.commit('AUTHENTICATION_SUCCESS', user);
          context.dispatch('app/getAppResources', null, { root: true });
          if (userResponseData.SiteID) {
            router.push({ name: 'home' });
          } else {
            router.push({ name: 'site-management' });
          }
        } else {
          context.dispatch('showConfirmSiginError');
        }
      })
      .catch(() => {
        context.dispatch('signOut');
      });
  },
  showConfirmSiginError: (context) => {
    Vue.swal({
      title: i18n.tc('common_an_error_occurred'),
      text: i18n.tc('auth_an_error_occurred_during_authentication_please_login_again'),
      // eslint-disable-next-line global-require
      imageUrl: require('@/assets/images/icons/bell-warning.png'),
      imageWidth: 96,
      imageHeight: 96,
      imageAlt: 'An error occurred',
      showClass: {
        popup: 'animate__animated animate__shakeX',
      },
      customClass: {
        confirmButton: 'btn btn-confirm',
      },
      confirmButtonText: i18n.tc('common_login'),
      buttonsStyling: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then(result => {
      if (result.value) {
        context.commit('LOGOUT_REQUEST_SUCCESS');
        context.dispatch('app/clearResource', null, { root: true });
        window.location.replace(process.env.VUE_APP_OIDC_PROVIDER_DOMAIN);
      }
    });
  },
  switchSite(context, siteID) {
    const bodyData = {
      SiteId: siteID,
    };
    switchSiteAPI({ BodyData: bodyData }).then((res) => {
      if (res.IsSuccess) {
        const tokenUpdateResponseData = res.Data;
        context.commit('UPDATE_TOKEN', tokenUpdateResponseData);
        context.dispatch('app/getAppResources', null, { root: true });
        router.push({ name: 'home' });
      }
    });
  },
  signoutAPI(context) {
    signout({})
      .then((res) => {
        if (res.IsSuccess) {
          context.commit('LOGOUT_REQUEST_SUCCESS');
          context.dispatch('app/clearResource', null, { root: true });
          setTimeout(() => {
            router.push({ name: 'home' });
          }, 1000);
        }
      }).catch(() => {
        context.commit('LOGOUT_REQUEST_FAILURE');
      });
  },
};

const mutations = {
  TOKEN_REQUEST_PENDING: (state) => {
    state.oidcAction = { loading: true };
  },
  TOKEN_REQUEST_SUCCESS: (state) => {
    state.oidcAction = { loading: false };
  },
  TOKEN_REQUEST_FAILURE: (state, error) => {
    state.user = { error };
    state.oidcAction = { error };
  },
  LOGOUT_REQUEST_PENDING: (state) => {
    state.oidcAction = { loading: true };
  },
  LOGOUT_REQUEST_SUCCESS: (state) => {
    state.user = {};
    state.oidcAction = {};
  },
  LOGOUT_REQUEST_FAILURE: (state, error) => {
    state.oidcAction = { error };
  },
  AUTHENTICATION_SUCCESS: (state, user) => {
    state.user = user;
    state.oidcAction = {};
  },
  UPDATE_TOKEN: (state, tokenUpdate) => {
    if (state.user && tokenUpdate) {
      Object.keys(state.user).forEach((k) => {
        if (k in tokenUpdate) {
          state.user[k] = tokenUpdate[k];
        }
      });
    }
  },
};

const getters = {
  accessToken(state) {
    return state.user.AccessToken;
  },
  isLoading(state) {
    if (state.oidcAction && 'loading' in state.oidcAction && state.oidcAction.loading) {
      return true;
    }
    return false;
  },
  tokenResponse(state) {
    return state.user ? state.user : {};
  },
  userProfile(state) {
    return state.user && state.user.Profile != null ? state.user.Profile : {};
  },
  userIsAuthenticated(state) {
    if (
      state.user &&
      'ezIDAccessToken' in state.user &&
      state.user.ezIDAccessToken
    ) {
      return true;
    }
    return false;
  },
  currentSiteID(state) {
    if (state.user && 'SiteID' in state.user) {
      return state.user.SiteID;
    }
    return null;
  },
};

export default {
  namespaced: true,
  state: defaultState,
  getters,
  actions,
  mutations,
};
