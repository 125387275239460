<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>

    <scroll-to-top v-if="enableScrollToTop" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { loadLocaleMessages } from '@/libs/i18n'
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'
import { getSessionUserSiteInfoAPI } from '@/api/action-defines/account'

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  computed: {
    ...mapGetters({
      $ls_IsAuthenticated: 'auth/userIsAuthenticated',
      $ls_CurrentSiteID: 'auth/currentSiteID',
      $ls_IsLoadingOIDC: 'auth/isLoading',
    }),
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    // Demos will still use Options API for ease
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  async created() {
    const self = this;
    await loadLocaleMessages('vi');
    if (!this.$ls_IsLoadingOIDC && this.$ls_IsAuthenticated) {
      this.$store.dispatch('app/getAppResources');
      if (!this.$ls_CurrentSiteID) {
        this.$router.push({ name: 'site-management' });
      } else {
        getSessionUserSiteInfoAPI().then(res => {
          if (res.IsSuccess) {
            self.checkSessionUserSite(res.Data);
          }
        });
      }
    }
  },
  methods: {
    checkSessionUserSite(sessionUserSiteInfo) {
      if (sessionUserSiteInfo.SessionStatus === 'EXPIRED') {
        this.$swal({
          title: this.$t('auth_session_has_expired'),
          text: this.$t('auth_please_login_again_to_continue'),
          showClass: {
            popup: 'animate__animated animate__shakeX',
          },
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          confirmButtonText: this.$t('common_ok'),
          buttonsStyling: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
        }).then(result => {
          if (result.value) {
            const requiresAuth = true;
            this.$store.dispatch('auth/checkAccess', requiresAuth).then((statusCode) => {
              if (statusCode !== 'OK') {
                this.$store.commit('auth/LOGOUT_REQUEST_SUCCESS');
                this.$store.dispatch('auth/signin');
              }
            });
          }
        });
      }
    },
  },
}
</script>
