export default {
  name: 'FilterCheckbox',
  components: {},
  props: {
    value: {
      type: Array,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    columns: {
      type: Number,
      required: false,
      default: 1,
    },
    placeholder: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      open: false,
      selectedItem: this.value,
    };
  },
  created() {},
  watch: {},
  computed: {
    listItem() {
      return this.options;
    },
  },
  methods: {
    clickOutSide() {
      this.open = false;
    },
    updateValue() {
      this.$emit('input', this.selectedItem);
    },
    onClickPicker() {
      this.open = !this.open;
    },
  },
};
