/**
 * OpenID Connect
 * @author QuocPhong88L1 <phongpq2402@gmail.com>
 */
import Oidc from 'oidc-client';
import { authConfig} from '@/auth/oidc/auth.config';

Oidc.Log.logger = console;
Oidc.Log.level = (process.env.NODE_ENV === 'production') ? Oidc.Log.ERROR : Oidc.Log.DEBUG;

let instance;

// OIDC Client
export const getOidcClient = () => {
  if (instance) {
    return instance;
  }

  instance = new Oidc.UserManager({
    userStore: new Oidc.WebStorageStateStore(),  
    authority: process.env.VUE_APP_OIDC_PROVIDER_DOMAIN,
    client_id: authConfig.CLIENT_ID,
    client_secret: process.env.VUE_APP_OIDC_CLIENT_SECRET,
    redirect_uri: authConfig.REDIRECT_URI,
    response_type: authConfig.RESPONSE_TYPE,
    scope: authConfig.SCOPES,
    post_logout_redirect_uri: authConfig.LOGOUT_REDIRECT_URI,
    // accessTokenExpiringNotificationTime: 10,
    automaticSilentRenew: true,
    filterProtocolClaims: true,
    loadUserInfo: true,
    // includeIdTokenInSilentRenew : false
  });
  return instance;
}