export default [
  {
    header: 'Forms & Tables',
  },
  {
    title: 'Forms Elements',
    icon: 'ez_k2',
    children: [
      {
        title: 'Input',
        route: 'forms-element-input',
      },
      {
        title: 'Input Group',
        route: 'forms-element-input-group',
      },
      {
        title: 'Input Mask',
        route: 'forms-element-input-mask',
      },
      {
        title: 'Textarea',
        route: 'forms-element-textarea',
      },
      {
        title: 'Auto Suggest',
        route: 'extensions-auto-suggest',
      },
      {
        title: 'Checkbox',
        route: 'forms-element-checkbox',
      },
      {
        title: 'Radio',
        route: 'forms-element-radio',
      },
      {
        title: 'Switch',
        route: 'forms-element-switch',
      },
      {
        title: 'Select',
        route: 'forms-element-select',
      },
      {
        title: 'Vue Select',
        route: 'extensions-vue-select',
      },
      {
        title: 'Spinbutton',
        route: 'forms-element-spinbutton',
      },
      {
        title: 'File Input',
        route: 'forms-element-file-input',
      },
      {
        title: 'Quill Editor',
        route: 'extensions-quill-editor',
      },
      {
        title: 'Form Datepicker',
        route: 'forms-element-datepicker',
      },
      {
        title: 'Form Timepicker',
        route: 'forms-element-timepicker',
      },
      {
        title: 'Date Time Picker',
        route: 'extensions-date-time-picker',
      },
      {
        title: 'Form Rating',
        route: 'forms-element-rating',
      },
      {
        title: 'Form Tag',
        route: 'forms-element-tag',
      },
    ],
  },
  {
    title: 'Form Layout',
    route: 'form-layout',
    icon: 'ez_k2',
  },
  {
    title: 'Form Wizard',
    route: 'form-wizard',
    icon: 'ez_k2',
  },
  {
    title: 'Form Validation',
    route: 'form-validation',
    icon: 'ez_k2',
  },
  {
    title: 'Form Repeater',
    route: 'form-repeater',
    icon: 'ez_k2',
  },
  {
    title: 'BS Table',
    route: 'table-bs-table',
    icon: 'ez_k2',
  },
  {
    title: 'Good Table',
    route: 'table-good-table',
    icon: 'ez_k2',
  },
  {
    title: 'Data Grid',
    route: 'table-data-grid',
    icon: 'ez_k2',
  },
];
