import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
// Routes
import routes from '@/router/router.define'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes,
})

router.beforeEach((to, _, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
  if (store.getters['auth/userIsAuthenticated']) {
    next()
  } else if (!store.getters['auth/isLoading']) {
    store.dispatch('auth/checkAccess', requiresAuth).then((statusCode) => {
      if (statusCode === 'OK') {
        next()
      } else {
        store.dispatch('auth/signin')
      }
    })
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
