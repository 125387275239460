import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { getTranslationApi } from '@/api/action-defines/common';

Vue.use(VueI18n);

export async function loadLocaleMessages(langCode) {
  await getTranslationApi({ QueryParameters: { languageCode: langCode } }).then(
    (res) => {
      i18n.setLocaleMessage(langCode, res.Data);
      i18n.locale = langCode;
    }
  );
}

export const i18n = new VueI18n({
  locale: 'vi', // set locale
  fallbackLocale: 'vi',
  messages: {}, // set locale messages
});
