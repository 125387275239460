import * as axios from 'axios';
import store from '@/store';
import { responseFormatter, errorFormatter } from '@/api/gateway/gatewayFormatter';

const gateway = axios.create({
    baseURL: '/api/',
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json' 
    },
    responseType: 'json',
});
gateway.interceptors.request.use(cfg => {
    // Do something before request is sent
    const accessToken = store.getters['auth/accessToken'];
    if (accessToken) cfg.headers.Authorization = `Bearer ${accessToken}`;
    cfg.validateStatus = status => status < 500; // Reject only if the status code is greater than or equal to 500
    return cfg;
});
gateway.interceptors.response.use(
    response => {
        if (response.status !== 200 && response.status !== 202) return Promise.reject(errorFormatter(response));
        return responseFormatter(response);
    },
    error => Promise.reject(errorFormatter(error)),
);

export default gateway;