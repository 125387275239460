export default [
  {
    path: '/configuration/site-management',
    name: 'site-management',
    component: () =>
      import('@/views/modules/site-management/SiteManagement.vue'),
    meta: {
      requiresAuth: true,
    },
  },
];
