import gateway from '@/api/gateway/gateway'

const BASE_SUCCESS_STATUS_CODE = '200'

export const $GET = ({ EndPoint, QueryParameters }) => {
  let parameterURL = ''
  if (QueryParameters !== null && Object.keys(QueryParameters).length > 0) {
    Object.keys(QueryParameters).forEach((parameterKey, index) => {
      parameterURL +=
        index === 0
          ? `?${parameterKey}=${QueryParameters[parameterKey]}`
          : `&${parameterKey}=${QueryParameters[parameterKey]}`
    })
  }
  if (parameterURL != '') {
    EndPoint = `${EndPoint}${parameterURL}`
  }
  return new Promise((resolve, reject) => {
    gateway
      .get(EndPoint)
      .then(
        (response) => {
          const responseData = response.data
          responseData.IsSuccess =
            responseData.StatusCode === BASE_SUCCESS_STATUS_CODE
          return resolve(responseData)
        },
        (error) => {
          return reject(error)
        }
      )
      .catch((error) => {
        return reject(error)
      })
  })
}

export const $POST = ({ EndPoint, BodyData = null }) =>
  new Promise((resolve, reject) => {
    gateway
      .post(EndPoint, BodyData)
      .then(
        (response) => {
          const responseData = response.data
          responseData.IsSuccess =
            responseData.StatusCode === BASE_SUCCESS_STATUS_CODE
          return resolve(responseData)
        },
        (error) => {
          return reject(error)
        }
      )
      .catch((error) => {
        return reject(error)
      })
  })
